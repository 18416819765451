import * as moment from 'moment';

export const graphMulti = {
    rolesCanShowBannerInfo: [],
    filters: {
        progress: {
            always: [{
                label: 'learner',
                value: null,
                custom: {
                    rules: ['required']
                }
            }],
            hidden: [{
                label: 'startDate',
                value: moment().add(-2, 'weeks').startOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }, {
                label: 'endDate',
                value: moment().endOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }, {
                label: 'concept',
                value: null,
                custom: {
                    rules: ['allowEmpty', 'autofill:multiLesson']
                }
            }, {
                label: 'educationalLevel',
                value: null,
                custom: {
                    rules: ['allowEmpty', 'autofill:multiLesson']
                }
            }, {
                label: 'multiLesson',
                value: null,
                custom: {
                    rules: ['required']
                }
            }]
        },
        ownProgress: {
            always: [{
                label: 'startDate',
                value: moment().add(-2, 'weeks').startOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }, {
                label: 'endDate',
                value: moment().endOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }],
            hidden: [{
                label: 'concept',
                value: null,
                custom: {
                    rules: ['allowEmpty', 'autofill:multiLesson']
                }
            }, {
                label: 'multiLesson',
                value: null,
                custom: {
                    rules: ['required']
                }
            }]
        }, attendance: {
            always: [{
                label: 'startDate',
                value: moment().add(-2, 'weeks').startOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }, {
                label: 'endDate',
                value: moment().endOf('day').toDate(),
                custom: {
                    rules: ['required']
                }
            }, {
                label: 'group',
                value: null
            }, {
                label: 'workgroup',
                value: null
            }],
            hidden: []
        }
    },
    graphs: {
        attendance: {
            forceFilteringByConcept: true,
        }
    },
};